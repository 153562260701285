import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import MerchPreviewGrid from '../components/merch-preview-grid'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import Background from '../components/background'

import SEO from '../components/seo'
import Layout from '../containers/layout'
import styles from '../styles/layout.css'

import { responsiveTitle1 } from '../components/typography.module.css'

export const query = graphql`
  query MerchPageQuery {
    merch: allSanityMerch(limit: 12, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawDescription
          slug {
            current
          }
        }
      }
    }
  }
`

const MerchPage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  console.log(props.data)
  const merchNodes =
    data && data.merch && mapEdgesToNodes(data.merch).filter(filterOutDocsWithoutSlugs)
  return (
    <Layout>
      <SEO title='Merch' />
      <Container>
        <Background />
        <h2 className={styles.merchTitle}>Coming Soon - Merch for all Consultants & Partners</h2>
        {merchNodes && merchNodes.length > 0 && <MerchPreviewGrid nodes={merchNodes} />}
      </Container>
    </Layout>
  )
}

export default MerchPage
