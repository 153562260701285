import { Link } from 'gatsby'
import React from 'react'
import { cn, buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import styles from './merch-preview.module.css'

function MerchPreview (props) {
  return (
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(800)
              .height(600)
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </div>
  )
}

export default MerchPreview
